.text-and-title-component {
     width: 100%;
     scale: 0.5;
     opacity: 0;
     transition: 2s;
     &.active{
          scale: 1;
          opacity: 1;
          
     }
     h1 {
          font-family: "Title_font";
          margin-bottom: 28px;
          font-size: 32px;
          display: inline-block;
     }
     p {
          font-size: 20px;
          line-height: 26px;
          color: rgba(255, 255, 255, 0.7);
     }

     @media (max-width: 900px) {
          h1 {
               font-size: 32px;
          }
     }
     @media (max-width: 500px) {
          h1 {
               font-size: 6vw;
               margin-bottom: 10px;
          }
          p {
               font-size: calc(1vw + 10px);
               line-height: calc(1vw + 15px);
          }
     }
}
