.for-who-body-component {
  display: flex;
  padding: 0 10%;
  justify-content: space-between;
  align-items: center;
  &.active {
    .image,
    .description {
      translate: 0;
      scale: 1;
      opacity: 1;
    }
  }
  .image {
    width: 45%;
    aspect-ratio: 8/11;
    transition: 2s;
    translate: -100%;    opacity: 0;

  }
  .description {
    width: 45%;
    display: flex;
    flex-direction: column;
    gap: 100px;
    transition: 2s;
    translate: 100%;
    opacity: 0;
  }

  @media (max-width: 900px) {
    flex-direction: column;
    row-gap: 100px;
    .description {
      width: 100%;
    }

    .image {
      aspect-ratio: 11/8;
      width: 100%;
    }
  }
  @media (max-width: 600px) {
    row-gap: 50px;
    .description {
      gap: 50px;
    }
  }
}
