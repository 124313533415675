.input {
  padding: 20px 40px;
  border-radius: 60px;
  border: #060606;
  font-size: 20px;
  width: 100%;
  max-width: 400px;
  border: 1px solid #303030;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &:focus {
    border: 1px solid #c0ff0300;

    outline: 2px solid #c0ff03;
  }
  @media (max-width: 600px) {
    padding: 10px 20px;
    font-size: 15px;
  }
}
