 

#cursor {
     position: fixed;
     top: 0;
     left: 0;
     padding: 10px;
     background-color: rgb(255, 255, 255);
     translate: -50% -50%;
     border-radius: 50%;
     transition: scale 300ms ease;
     z-index: -1;
     &.active {
          scale: 7;
     }
     &.not-visible {
          display: none;
     }
}

@media (max-width: 1200px) {
     #cursor {
          display: none;
     }
}
