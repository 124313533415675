#button {
  padding: 20px 40px;
  display: inline-block;
  border-radius: 50px;
  font-size: 24px;
  display: flex;
  gap: 20px;
  align-items: center;
  border: 1px solid rgba(192, 255, 3, 0.2);
  transition: 500ms;
  color: white;
  background: linear-gradient(80deg, #c0ff03, #99cc00, #c0ff03);
  &:active {
    background: linear-gradient(80deg, #99cc00, #a9e200, #99cc00);
  }
  &.dark {
    color: rgb(192, 255, 3);
    background: linear-gradient(181.26deg, rgba(192, 255, 3, 0) -33.18%, #c0ff0328 98.93%);
    backdrop-filter: blur(45px);
    &:active {
      background: linear-gradient(90deg, rgba(192, 255, 3, 0) -33.18%, #c0ff0328 98.93%);
    }
  }

  &:hover {
    scale: 1.08;
  }
}

@media (max-width: 800px) {
  #button {
    font-size: 18px;
    padding: 15px 30px;
  }
}
@media (max-width: 600px) {
  #button {
    font-size: 14px;
    padding: 12px 24px;
  }
}
