.coursehead-section {
  padding-inline: 10%;
  padding-top: 100px;
  color: white;

  &.active {
    h1,
    a {
      opacity: 1;
      translate: 0;
    }
  }
  h1 {
    color: #c0ff03;
    translate: -100%;
    opacity: 0;
    transition: 1.7s;
    opacity: 0;
  }
  a {
    transition: 2s;
    opacity: 0;
    translate: 0 200px;
  }
  .giant-body {
    max-width: 350px;
  }
  @media (max-width: 700px) {
    h1 {
      font-size: 7vw !important;
    }
  }
}
