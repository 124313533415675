.titlewith-p-component {
  h1 {
    translate: -400px;
    opacity: 0;
    transition: 3s;
  }
  p {
    translate: 400px;
    opacity: 0;
    transition: 2s;
    max-width: 400px;
   }
  div {
    display: flex;
    align-items: center;
    // max-width: 600px;
    gap: 30px;
  }

  &.active {
    h1,
    p {
      translate: 0;
      opacity: 1;
      opacity: 1;
    }
  }
  &.small {
    font-size: 20px;
    @media (max-width: 1000px) {
     p {
       font-size: 14px;
       max-width: 250px;
     }
   }
   @media (max-width: 500px) {
     p {
       font-size: 10px;
     }
   }
  }
  @media (max-width: 500px) {
    p {
      font-size: 12px;
    }
  }
}
