.success-data-component {
  padding-inline: 10%;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100vh;
  h1 {
    margin: 0 auto;
    max-width: 900px;
  }
  p {
    padding: 5vh 0 10vh;
    max-width: 470px;
    font-size: 20px;
    line-height: 26px;
    color: #fdfdfd;
  }
  & > a {
    padding: 26px 0 80px;
    font-size: 24px;
    line-height: 26px;
    color: #fdfdfd;
    display: flex;
    gap: 15px;
  }
  .socials {
    display: flex;
    gap: 2vw;
  }
  @media (max-width: 400px) {
    display: flex;
    flex-direction: column;
    gap: 10vw;
    height: unset;
    & > a,
    p {
      padding: 0;
    }
    h1 {
      font-size: 7vw !important;
    }
    p {
      font-size: 15px;
      line-height: 20px;
    }
    h2 {
      font-size: 20px;
    }
    a {
      font-size: 15px;
      align-items: center;
    }
  }
}
