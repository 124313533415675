@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap");

@font-face {
  font-family: "Title_font" ;
  src: url("./assets/fonts/titlefont.ttf");
}

* {
  padding: 0;
  margin: 0;
  color: inherit;
  background-color: transparent;
  text-decoration: none;
  box-sizing: border-box;
  font-family: "Space Grotesk", sans-serif;
  // outline: 1px solid rgb(random(255), random(255), random(255));
}
.title-font {
  font-family: "Title_font" , sans-serif;
}

.padding-line {
  margin: 80px 0;
  @media (max-width: 1000px) {
    margin: 10vw 0;
  }
}
.space-gotex {
  font-family: "Space Grotesk", sans-serif;
}
body {
  background-color: rgb(1, 0, 0);
  // overflow: hidden;
}

html {
  // scroll-behavior: smooth;
}

a {
  display: inline-block;
}

::-webkit-scrollbar-track {
  width: 10px;
}

::-webkit-scrollbar {
  width: 7px;
  // display: none;
}
::-webkit-scrollbar-thumb {
  background: #c0ff0391;
  border-radius: 10px;
}

::selection {
  background: #88b600c2;
  color: white;
}

.lazy-load {
  opacity: 0;
  transition: opacity 500ms;
}

.padding-block {
  padding-block: 150px;
  @media (max-width: 700px) {
    padding-block: 50px;
  }
}
