.line-component {
  width: 100%;
  height: 2px;
  position: relative;
  overflow: hidden;
  background-color: #bfff001c;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: gren 10s ease infinite;
    // background: linear-gradient(to left, #ffffff0d, 50% #c0ff03, #ffffff0d);
    background: linear-gradient(to left, #ffffff00, #c0ff038b, #ffffff00);
  }
}

@keyframes gren {
  0% {
    translate: -100%;
  }
  50% {
    translate: 0%;
  }
  100% {
    translate: 100%;
  }
}
