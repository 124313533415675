.image {
     width: 100%;
     height: 100%;
     display: flex;
     align-items: center;
     position: relative;
     img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          border-radius: 1000px;
          transition: 0;
          opacity: 0;
          translate: -30%;
     }
     &.animate img {
          transition: 1s;
          translate: 0%;
          opacity: 1;
     }
     &.animate::before {
          transition: 1s;
          translate: 6% -44%;
          opacity: 1;
     }
     &::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          width: 100%;
          height: 100%;
          translate: -30% 10%;
          opacity: 0;
          border-radius: 1000px;
          border: 1px solid #fdfdfd;
          z-index: -1;
     }
}
