.order-form-section {
     padding-inline:  10%;
     color: white;   
 position: relative;
     form {
          display: flex;
          gap: 20px;
          padding-top: calc(5vw + 20px);
          // flex-wrap: wrap;
     }
     @media (max-width: 1100px) {
 
          form {
               flex-wrap: wrap;
               justify-content: space-between;
          }
          .input,
          button {
               width: 48%;
          }
     }
     @media (max-width: 640px) {
          form {
               justify-content: center;
          }
          .input {
               max-width: unset;
               width: 100%;
          }
          button {
               width: auto;
          }
     }
}
