.course-numbers-component {
     display: flex;
     padding-top: 140px;
     gap: 5%;
     row-gap: 30px;
     justify-content: space-between;
     p {
          padding-top: 20px;
          color: #ffffffb2;
     }
     @media (max-width: 720px) {
          flex-wrap: wrap;
          padding-top: 60px;
          div {
               width: 47%;
          }
     }
}
