.title-body {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  opacity: 0;
  transition: 2s;

  div {
    width: 50%;
    font-size: 20px;
    line-height: 26px;
    //   translate: 400px;
     translate: 150%;
     scale: .8;    transition: 2s;

   }
  p {
    width: 50%;
    font-family: "Title_font";
    font-size: 32px;
    //   translate: -400px;
    max-width: 600px;
    translate: -150%;
    transition: 2.3s;
    opacity: 0;
    scale: .7;
  }

  &.active {
    opacity: 1;
    div,
    p {
       opacity: 1;
      scale: 1;
      translate: 0;
    }
  }
}

@media (max-width: 1000px) {
  .title-body {
    p {
      font-size: 20px;
    }
    div {
      font-size: 15px;
      line-height: 20px;
    }
    padding-bottom: 20px;
  }
}

@media (max-width: 600px) {
  .title-body {
    flex-wrap: wrap;
    div,
    p {
      width: 100%;
    }
  }
}
