.title {
  font-family: "Title_font", sans-serif;
  font-size: 72px;
  display: inline-block;
  text-transform: capitalize;
  transition: 2s;
  &.left {
    translate: -100%;
    opacity: 0;
   }
  &.active {
    opacity: 1;
    translate: 0;
  }
  @media (max-width: 1000px) {
    font-size: calc(30px + 3vw);
  }
  @media (max-width: 500px) {
    font-size: calc(30px + 1vw);
  }
  &.large {
    font-size: 120px;
    @media (max-width: 1000px) {
      font-size: 80px;
    }
    @media (max-width: 500px) {
      font-size: 60px;
    }
  }
  &.small {
    font-size: 55px;
    @media (max-width: 1000px) {
      font-size: 37px;
    }
    @media (max-width: 600px) {
      font-size: 30px;
    }
    @media (max-width: 500px) {
      font-size: 6vw;
    }
  }
}
